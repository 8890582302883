.spaLayout{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    padding: 12px 16px;
    padding-bottom: 70px;
    position: absolute;
    left: 0;
    right: 0;
}
.spaLayout>.arrowBack{
  width: 36px;
  margin-bottom: 12px;
}

.spaService>h5{
    font-size: 18px;
    margin-top: 16px;
    margin-bottom: 10px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.2;
}
.spaService>span{
    display: flex;
    height: 4px;
    width: 30%;
    background-color: #d8d8d8;
    margin-bottom: 24px;
}