.textArea{
    resize: none;
    padding: 6px;
    box-sizing: border-box;
    border: 1px solid #696969;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    height: fit-content;
    min-height: 50px;

}