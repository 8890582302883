

.datePickerWrapper>a{
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
    opacity: .5;
    width: 100%;
    max-width: 22ch;
    display: flex;
    text-align: end;
    align-items: center;
}
.datePickerWrapper>div:nth-child(7)>div{
    position: fixed;
  border: 2px solid red!important;}

