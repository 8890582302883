.layout{
    box-sizing: border-box;
    padding: 49px 0;
    padding-top: 66px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    height: 100%;
}