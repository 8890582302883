.laundryLayout{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    padding: 12px 16px;
    padding-bottom: 150px;
    position: absolute;
    left: 0;
    right: 0;
}
.laundryLayout>.arrowBack{
  width: 36px;
  margin-bottom: 12px;
}
.laundryLayout>p{
    font-size: 14px;
}

.laundryService>h5{
    font-size: 17px;
    margin-top: 16px;
    margin-bottom: 16px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.2;
}
.laundryService>laundryn{
    display: flex;
    height: 4px;
    width: 30%;
    background-color: #d8d8d8;
    margin-bottom: 24px;
}