.orderFoodLayout{
    background-color: #fcfcf7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    padding: 0 20px;
    padding-bottom: 100px;
    box-sizing: border-box;
    position: relative;
    width: 100%;
}
.orderFoodLayout>.arrowBack{
  width: 36px;
  margin-bottom: 12px;
}
.orderFoodLayout>.banner{
  width: auto;
  height: 144px;
  border-radius: 8px;
}

.fixedLayout{
  background-color: #fcfcf7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 20px;
  padding-bottom: 100px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.fixedLayout>.arrowBack{
width: 36px;
margin-bottom: 12px;
}
.fixedLayout>.banner{
width: auto;
height: 144px;
border-radius: 8px;
}
.menuMainTitle{
    border-bottom:4px solid darkolivegreen;
}
.menuMainTitle>h6{
  font-weight: 500;
  font-size: 16px;
  margin: 12px 0 10px 0;
}
.dishesCards{
  width: 100%;
}
.menuMainTitle>p{
    font-weight: 500;
    font-size: 12px;
    margin: 0 0 20px 0;
  }
  .saladsBox{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  }
.saladsBoxTitle{
    width: 100%;
    padding: 24px 0;
    display: flex;
    align-items: center;
    justify-content: center;
   
}
.saladsBoxTitle>p{
  font-size: 16px;
  color: #696969;
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: nowrap;
}
.saladsBoxTitle>span{
    display: flex;
    height: .5px;
    background-color: #69696960;
    width:100%;
 
}
.saladsBoxTitle>span:first-child{
    margin-right: 10px;
}
.saladsBoxTitle>span:last-child{
    margin-left: 10px;
}
.menuButtonWrapper{
  
  position: fixed;
  bottom: 110px;
  right: 15px;
  z-index: 99998;
}
.menuWrapper{
  position: fixed;
    right: 20px;
    bottom: 150px;
    height: 50vh;
    overflow: scroll;
    z-index: 9998;
}
.overlay{
  position: fixed;
  background-color: #fff;
  filter: brightness(.2);
  opacity: .6;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: auto;
}
.noOverlay{
  display: none;
}
.cartCounterWrapper{
  z-index: 9;
}
.hideMenu{
  display: none;
}