.viewCart{
    height: 54px;
    position: fixed;
    box-sizing: border-box;
    left: 0;
    bottom: 46px;
    right: 0;
    background-color: #fff;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(0px -2px 6px #69696960);
}
.viewCart>span{
        width: 88%;
        margin: auto;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        background-color: #7d9371;
        border: none;
        outline: none;
        border-radius: 3px;  
}
.viewCart>span>span{
    display: flex;
    margin: 0;
}
.viewCart>span>span>p{
    color: #fff;
    font-size: 13px;
    margin: 0 3px ;
}
.viewCart>span>a{
    color: #fff;
    font-size: 13px;
    margin: 0;
}

.paybox{
    height: 64px;
    position: fixed;
    box-sizing: border-box;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #fff;
    z-index: 99909;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(0px -2px 6px #69696960);
}
.paybox>span{
        width: 88%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-around;
        border: none;
        outline: none;
        border-radius: 3px;  
}
.paybox>span>span{
    display: flex;
    flex-direction: column-reverse;
    margin: 0;
}
.paybox>span>span>p{
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    color: #333;
}
.paybox>span>span>p:first-child{
 
    font-weight: 600;
   
}
.paybox>span>a{
    color: #fff;
    font-size: 13px;
    margin: 0;
    background-color: #7d9371;
    display: flex;
    padding: 10px 20px;
}


.disabledButton {
    opacity: 0.35;
    cursor: not-allowed;
}