.welcomeLayout{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:flex-start;
    box-sizing: border-box;
    padding: 12px 16px;
    padding-bottom: 70px;
    position: absolute;
    left: 0;
    right: 0;
}

.welcomeLayout>img{
  width: 100%;
  border-radius: 8px;
  margin-bottom: 24px;
}

.arrowBack{
  width: 32px!important;
  margin-bottom: 12px!important;

}