.laundryConfirmation{
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.laundryConfirmation>p{
    margin: 3px 0;
}
.laundryConfirmation>img{
    width: 40%;
    margin-bottom: 24px;
}