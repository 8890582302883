.card{
    cursor: pointer;
    width: 100%;
    border-radius: 8px;
    height: 144px;
    position: relative;
    margin-bottom:10px; 
}
.cover{
    width: 100%;
    height: inherit;
    object-fit: cover;
    object-position: top center;
    border-radius: 8px;
}
.headingWrapper{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #696969;
    opacity: .85;
    padding: 10px 0;
    border-radius: 0 0 8px 8px;
}
.headingWrapper>h3{
    color: #fff;
    margin: 0;
    margin-left: 10px;
    opacity: .9;
    font-size: 14px;
    line-height: 1.2;
    font-weight: 500;
    text-transform: uppercase;
}