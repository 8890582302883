.spaCard{
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 16px;
}
.spaCard>p{
    font-size: 13px;
    font-weight: 500;
    color: #636362;
    margin: 2px 0;
    width: 72%;
}

.spaCard>h6{
    margin: 2px 0 0 0;
    font-weight: 600;
    font-size: 13px;
}

.bookButtonWrapper{
    position: absolute;
    right: 0;
    top: 0;
}