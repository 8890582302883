.login{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    height: 100%;
    position: absolute;
    z-index: 99;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #fcfeff;
}
.closeButton{
    color: #696969;
    font-weight: 600;
    font-size: 18px;
    position: absolute;
    z-index: 999;
    top: 12px;
    text-decoration: none;
    right: 12px;
}
.login > img{
    width: 54%;
    margin-bottom: 24px;
}
.buttonWrapper{
    margin-top: 36px;
}
.disclaimerWrapper{
    width: 90%;
    margin: 0 auto;
}
.disclaimerWrapper > p{
    font-size: 14px;
    font-style: italic;
    margin: 10px 0;
    color: #adadad;
    text-align: justify;
}