.dishName{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.dishName>img{
    width: 18px;
    margin-right: 6px;
}
.dishName>h6{
    font-size: 13px;
    font-weight: 500;
    margin: 0;
    width: 60%;
    text-transform: capitalize!important;
}
.menuCard{
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 16px;
    width: 100%;

}
.menuCard>span{
    font-size: 13px;
    margin: 5px 0;
    font-weight: 400;
}
.menuCard>span:last-child{
    font-size: 11px;
}
.addButtonWrapper{
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.taxedPrice{
    font-size: 12px;
}
.inclTaxes{
    font-size: 10px;
    font-weight: 400;
    font-style: italic;
}

.addonList {
    width: 50%;
}