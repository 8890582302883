.placeRequestLayout{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    padding: 12px 16px;
    padding-bottom: 70px;
    position: absolute;
    left: 0;
    right: 0;
}
.placeRequestLayout>.arrowBack{
  width: 36px;
  margin-bottom: 12px;
}
.placeRequestLayout>.banner{
  width: auto;
  height: 144px;
  border-radius: 8px;
  margin-bottom: 36px;
}
.placeRequestButton{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;
}

.bottlesOverlayInput{
  position: fixed;
  top: 0;
  bottom:0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .4);
  display: flex;
  align-items: center;
  justify-content: center;
}
.promptBox{
  background-color: #fff;
  padding: 30px;
  border-radius: 4px;
  filter: drop-shadow(3px 3px 6px #00000030);
}
.promptBox{
  font-size: 14px;
}
.promptButtons{
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}
.promptBox>.promptButtons>span{
  display: flex;
  width: 40%;
  justify-content: flex-end;
  color: dodgerblue;
  font-weight: 600;
  text-transform: uppercase;
}
.promptBox>div{
  width: 100%;
}
.promptBox>div>input{
  padding-left: 0;
}
.promptBox>div>input:focus{
  border-bottom: 1.6px solid dodgerblue;
}

