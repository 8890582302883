body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

h1,h2,h3,h4,h5,h6{
    font-family: 'Montserrat', sans-serif;
}

img{
    object-fit: cover;
    object-position: center;
}