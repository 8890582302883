.cloth{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.cloth>h6{
    font-size: 14px;
    font-weight: 600;
    margin: 0
}
.laundryCard{
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 16px;

}
.laundryCard>span{
    font-size: 14px;
    margin: 5px 0;
    font-weight: 400;
}

.addButtonWrapper{
    position: absolute;
    right: 0;
    top: 0;
}