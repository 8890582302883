.root {
  height: fit-content;
  padding: 14px;
  border-radius: 8px;
  margin-bottom: 16px;
}
.root > h4 {
  font-size: 14px;
  font-weight: 500;
  margin: 3px 0;
  color: #747474;
  text-transform: uppercase;
}
.root > p {
  font-size: 13px;
  font-weight: 500;
  margin: 3px 0;
  color: #747474;
  font-style: italic;
}
#root > div > div > div > div > p{
  font-style: italic;
}
