.dishName{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.dishName>img{
    width: 18px;
    margin-right: 6px;
}
.dishName>h6{
    font-size: 13px;
    font-weight: 500;
    margin: 0;
    width: 70%;
}
.menuCard{
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 16px;
    width: 100%;

}
.menuCard>span{
    font-size: 13px;
    margin: 5px 0;
    font-weight: 400;
}

.addButtonWrapper{
    position: absolute;
    right: 0;
    top: 0;
}