.counter{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    min-width: 60px;
    height: 20px;
    border: .5px solid #a9a9a9;
    border-radius: 5px;
  
    
}
.counter>div{
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33%;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    background-color: #a9a9a9;
}
.counter>div:nth-child(odd){
    border-radius: 3px 0 0 3px;
}
.counter>div:nth-child(2){
    background-color: #fff;
    color: #696969;
}
.counter>div:nth-child(3){
    border-radius: 0 3px 3px 0;
}