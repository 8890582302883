.tvGuideLayout{
  background-color: #fcfcf7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 12px 16px;
  padding-bottom: 70px;
  position: relative;
  box-sizing: border-box;
  width: 100%;
}
.tvGuideLayout>.arrowBack{
  position: absolute;
  width: 36px;
  top: 10px;
  left: 10px;
  margin-bottom: 12px;
}



.tvGuide>div:first-child{
  background-color: #696969;
  padding: 40px 20px;
  position: absolute;
  left: 0;
  right: 0;
  top: 60px;
}
.tvGuide>div>h3{
    font-weight: 500!important;
}
.tvGuide>div>ul>p{
    font-size: 14px;
    font-weight: 500;
    width: 90%;
}
.tvGuide>div>ul>li{
  width: 90%;
}
.tvGuide>div>ul>li>p{
    font-size: 14px;
    font-weight: 500;
    width: 90%;
}

.tvGuide>div:last-child{

    margin-top: 200px;
  }