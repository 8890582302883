.login{
    background-color: #3880ff;
    color: #fff;
    font-size: 14px;
    border: none;
    outline: none;
    padding: 12px 36px;
    text-transform: uppercase;
    border-radius: 8px;
}
.add{
    border: 1px solid #696969;
    border-radius: 5px;
    padding: 6px 15px;
    width: fit-content;
    background-color: transparent;
    color: #696969;
    font-size: 13px;
    font-weight: 400;
}
.book{
    border: 1.5px solid #255e71;
    border-radius: 5px;
    padding: 6px 15px;
    width: fit-content;
    background-color: #fff;
    color: #255e71;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
}
.back{
    color: #333;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    background-color: #f4f5f8;
    filter: drop-shadow(0px 3px 2px #69696950);
    display: flex;
    padding: 10px 30px;
    border: none;
    border-radius: 4px;
}
.proceed{
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    background-color: #7d9371;
    filter: drop-shadow(0px 3px 2px #69696950);
    display: flex;
    padding: 10px 30px;
    border: none;
    border-radius: 4px;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.placeRequest {
    background-color: #3880ff;
    font-size: 13px;
    color: #fff;
    padding: 10px 16px;
    border-radius: 20px;
    width: fit-content;
    border: none;
    filter: drop-shadow(3px 3px 6px #00000030);
}

.disabled {
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    margin: 0;
    opacity: 0.35;
    display: flex;
    padding: 10px 30px;
    border: none;
    border-radius: 4px;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;  
}

button:focus{
    outline: none;
    border: none;
}
