
header{
    position: fixed;
    width: 100%;
    z-index: 9;
    top:0;
    left: 0;
    right: 0;
    box-sizing: border-box;
    background-color: #fff;
    padding: 6px 20px;
 
}
.logo{
    width: 20%;
}