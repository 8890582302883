.foodAddonWrapper{
    background-color: #00000080;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 99999;
}
.foodAddonWindow{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #fff;
    padding: 24px;
    height: 50vh;
    padding-bottom: 100px;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
   
    overflow-y: scroll;
}
.foodAddonWindow>h2{
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 6px;
}
.choiceRow{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.closeAddon{
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 16px;
    font-weight: 500;
    color: #696969;
}
.choiceRow{
    margin-bottom: 10px;
}
.addButtonWrapper{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 10px #c8c8c8;
}
.addButtonWrapper>button{
    width: 100%;
    text-transform: uppercase;
}


.errorMessage{
    background-color: gold;
    color: #000;
    padding: 4px;
    font-size: 12px;
    padding-left: 20px;
}