.landing{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 20px;
}
.twoCardsPerRow{
    display: grid;
    grid-gap: 12px;
    grid-template-columns: repeat(2, 1fr);
}


.halfItem {
    width: 48%;
}