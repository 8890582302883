.reuseLayout{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    padding: 12px 16px;
    padding-bottom: 70px;
    position: absolute;
    left: 0;
    right: 0;
}
.reuseLayout>.arrowBack{
  width: 36px;
  margin-bottom: 12px;
}


